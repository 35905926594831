import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Anchor, Card, SimpleGrid, Title, Text, Button, Container, Image } from '@mantine/core';
import { createStyles } from "@mantine/styles";

import { useAuth } from "../components/AuthProvider";
import NavbarInfo from "../components/NavbarInfo";

export function Homepage() {

  const { classes, theme } = useStyles();

  const { user } = useAuth();

  const goToPage = (route) => {
    setActiveComponent(route);
    navigate(route);
  };

  const [activeComponent, setActiveComponent] = useState("/");
  const navigate = useNavigate();

  const features = data.map((feature) => (
    <Card
      key={feature.title}
      shadow="md"
      radius="md"
      className={classes.card}
      p="xl"
    >

      <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text size="sm" color="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  return (

    <Container size="md" py="xl">
      {user ?
        <>
          <NavbarInfo />
        </>
        :
        <>
          <Title className={classes.title} pl={5} align="center">
            Welcome to
            <Image src='clinipromptlogo.png' ml={-5} radius="sm" height={75} fit={"contain"} />
          </Title>

          <Text size="xl" fw={700} variant="gradient" gradient={{ from: 'blue.9', to: 'red.9', deg: 90 } } align="center">
            Bridging the gap between the healthcare industry and LLMs
          </Text>
          <SimpleGrid
            cols={3}
            spacing="xl"
            mt={50}
            breakpoints={[{ maxWidth: "md", cols: 1 }]}
          >
            {features}

            <Text> </Text>

            <Button type="submit" size="lg" align='center' variant="solid" colorScheme='blue'
              onClick={() => goToPage('/login')} >
              Sign In
            </Button>
          </SimpleGrid>
          <Text size="sm" c="grey" fw={400} style={{ width: '100%', marginTop: 15, marginBottom: 5}} align="center" >
            1. All examples within our database are either publicly available or synthetically generated.
          </Text>
          <Text size="sm" c="grey" fw={400} style={{ width: '100%', marginBottom: 5}} align="center" >
            2. If using CliniPrompt, please cite us with: Majid Afshar, Yanjun Gao, Graham Wills, Jason Wang, Matthew M Churpek, Christa J Westenberger, David T Kunstman, Joel E Gordon, Frank J Liao, Brian Patterson. “Prompt Engineering with a Large Language Model to Assist Providers in Responding to Patient Inquiries: A Real-Time Implementation in the Electronic Health Record”.
            (<Anchor href="https://academic.oup.com/jamiaopen/article/7/3/ooae080/7737652" target="_blank"> JAMIA Open. 2024.</Anchor>)
          </Text>
        </>
      }
    </Container>
  );
}

export default Homepage;

const data = [
  {
    title: "Various LLMs",
    description:
      "Our product provides flexibility in providing customers a wide range of diverse LLMs to choose from.",
    variant: "solid",
    colorScheme: "red"
  },
  {
    title: "Database for Prompts",
    description:
      "This product collects prompts over time to improve the recommendations and provide greater efficiency for healthcare workers.",
    variant: "solid",
    colorScheme: "red"
  },
  {
    title: "User Privacy",
    description:
      "Cliniprompt provides secure authentication for all user accounts.",
    variant: "solid",
    colorScheme: "red"
  }
];

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    [theme.fn.smallerThan("sm")]: {
      fontSize: 24
    }
  },

  description: {
    maxWidth: 600,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },

  card: {
    border: `10px solid' 
    ${theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
      }`
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm
    }
  }
}));
