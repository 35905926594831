// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyATXNnSYCQyjDEkLyGY6sw9MBGkdKTHrSs",
  authDomain: "cliniprompt.firebaseapp.com",
  projectId: "cliniprompt",
  storageBucket: "cliniprompt.appspot.com",
  messagingSenderId: "452168269864",
  appId: "1:452168269864:web:f59b5e82896ff25cc0351d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
